import styled from 'styled-components';

export const StyledLandingManageWithMobile = styled.div`
  .landing_manage-with-mobile {
    min-height: 500px;
    background-color: ${({ theme }) => theme.colors.tycoonMain};
    display: flex;
    align-items: center;

    &_content {
      width: 40%;
      margin-left: 50px;

      h1 {
        font-size: ${({ theme }) => theme.fontSizes.h1.xl};
        font-weight: ${({ theme }) => theme.fontWeights[4]};
        margin-bottom: 1em;
      }

      p {
        margin-bottom: 2em;
      }

      &_links {
        & > :first-child {
          margin-bottom: 1em;
        }

        & > :last-child {
          margin-left: 1em;
        }
      }
    }
  }
`;