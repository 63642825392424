import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Bullet from '../../common/components/Bullet';
import LinkComponent from '../../common/components/LinkComponent';
import { StyledLandingBenefits } from './landingBenefits.styled';

const LandingBenefits = () => {
  const { bulletOne, bulletTwo, bulletThree, bulletFour } = useStaticQuery(
    graphql`
      query {
        bulletOne: file(relativePath: {eq: "bullet-one.svg"}) {
          extension
          publicURL
        }
        bulletTwo: file(relativePath: {eq: "bullet-two.svg"}) {
          extension
          publicURL
        }
        bulletThree: file(relativePath: {eq: "bullet-three.svg"}) {
          extension
          publicURL
        }
        bulletFour: file(relativePath: {eq: "bullet-four.svg"}) {
          extension
          publicURL
        }
      }
    `
  );
  console.log(bulletOne);
  return (
    <StyledLandingBenefits>
      <div className="landing-benefits_partners">
        <LinkComponent to="https://www.binance.com/" title="Binance" target="_blank" type="external">
          <StaticImage src="../../common/assets/image/airbnb-logo.png" alt="Binance" />
        </LinkComponent>
        <LinkComponent to="https://www.bitgo.com/" title="BitGo" target="_blank" type="external">
          <StaticImage src="../../common/assets/image/bitgo-logo.png" alt="BitGo" />
        </LinkComponent>
        <LinkComponent to="https://www.google.com" title="Google" target="_blank" type="external">
          <StaticImage src="../../common/assets/image/google-logo.png" alt="Google" />
        </LinkComponent>
        <LinkComponent to="https://www.microsoft.com/" title="Microsoft" target="_blank" type="external">
          <StaticImage src="../../common/assets/image/microsoft-logo.png" alt="Microsoft" />
        </LinkComponent>
        <LinkComponent to="https://www.walmart.com/" title="Walmart" target="_blank" type="external">
          <StaticImage src="../../common/assets/image/walmart-logo.png" alt="Walmart" />
        </LinkComponent>
        <LinkComponent to="https://www.fedex.com/" title="FedEx" target="_blank" type="external">
          <StaticImage src="../../common/assets/image/fedex-logo.png" alt="FedEx" />
        </LinkComponent>
      </div>
      <div className="landing-benefits_list">
        <h1>Benefits of working<br /> with us</h1>
        <div className="landing-benefits_list_bullets">
          <Bullet
            title="Time zones ain't nothing"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis et sed nam sem tellus erat."
            icon={bulletOne.publicURL}
            className="landing-benefits_list_bullets_item"
          />
          <Bullet
            title="Time zones ain't nothing"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis et sed nam sem tellus erat."
            icon={bulletTwo.publicURL}
            className="landing-benefits_list_bullets_item"
          />
          <Bullet
            title="Time zones ain't nothing"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis et sed nam sem tellus erat."
            icon={bulletThree.publicURL}
            className="landing-benefits_list_bullets_item"
          />
          <Bullet
            title="Time zones ain't nothing"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis et sed nam sem tellus erat."
            icon={bulletFour.publicURL}
            className="landing-benefits_list_bullets_item"
          />
        </div>
      </div>
    </StyledLandingBenefits>
  );
};

export default LandingBenefits;
