import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Button from '../../common/components/Button';
import BackgroundContainer from '../BackgroundContainer';
import { StyledLandingTitle } from './landingTitle.styled';

const LandingTitle = () => {
  const { landingTitleImage, phoneMockup } = useStaticQuery(
    graphql`
      query {
        landingTitleImage: file(relativePath: {eq: "landing-title-background.png"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1300, maxHeight: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        phoneMockup: file(relativePath: {eq: "phone-mockup.png"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <StyledLandingTitle>
      <BackgroundContainer
        className="landing-title"
        fluid={[
          phoneMockup.childImageSharp.fluid,
          landingTitleImage.childImageSharp.fluid
        ]}
        backgroundPosition="right 10% bottom 30%, center"
      >
        <div className="landing-title_content">
          <h1>Be the best Trader<br /> or follow the Best.</h1>
          <Button title="Get Started">Get Started</Button>
        </div>
      </BackgroundContainer>
    </StyledLandingTitle>
  );
};

export default LandingTitle;
