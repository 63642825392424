import React from 'react';

import Layout from '../containers/Layout';
import Landing from '../containers/Landing';

const Index = ({ location }) => {
  return (
    <Layout title="Tycoon" description="Social Trading Platform" location={location.href}>
      <Landing />
    </Layout>
  );
}

export default Index;
