import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Bullet from '../../common/components/Bullet';
import Button from '../../common/components/Button';
import BackgroundContainer from '../BackgroundContainer';
import { StyledLandingHeadline } from './landingHeadline.styled';

const LandingHeadline = () => {
  const { landingHeadlineOne, landingHeadlineTwo, bulletOne, bulletTwo } = useStaticQuery(
    graphql`
      query {
        landingHeadlineOne: file(relativePath: {eq: "landing-headline-one.png"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        landingHeadlineTwo: file(relativePath: {eq: "landing-headline-two.png"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bulletOne: file(relativePath: {eq: "bullet-one.svg"}) {
          extension
          publicURL
        }
        bulletTwo: file(relativePath: {eq: "bullet-two.svg"}) {
          extension
          publicURL
        }
      }
    `
  );

  return (
    <StyledLandingHeadline>
      <div className="landing_headline_image-container">
        <BackgroundContainer
          className="landing_headline_image-container_image"
          fluid={landingHeadlineOne.childImageSharp.fluid}
          backgroundPosition="center"
        />
        <BackgroundContainer
          className="landing_headline_image-container_image"
          fluid={landingHeadlineTwo.childImageSharp.fluid}
          backgroundPosition="center"
        />
      </div>
      <div className="landing_headline_content">
        <h1>Headline</h1>
        <p>Specially developed to help restore a more youthful appearance with improved radiance and bounce.</p>
        <Bullet
          title="Headline"
          text="One line description"
          icon={bulletOne.publicURL} 
        />
        <Bullet
          title="Headline"
          text="One line description"
          icon={bulletTwo.publicURL} 
        />
        <Button title="Buy now">Buy now</Button>
      </div>
    </StyledLandingHeadline>
  );
};

export default LandingHeadline;
