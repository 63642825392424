import React from 'react';
import PropTypes from 'prop-types';

import { StyledCard } from './card.styled';

const Card = ({ title, text, icon, className }) => {
  return (
    <StyledCard className={className}>
      <h3>{title}</h3>
      {text && <p>{text}</p>}
      {icon && <img src={icon} alt={title} />}
    </StyledCard>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string
};

Card.defaultProps = {
  title: 'Card'
};

export default Card;
