import styled from 'styled-components';

export const StyledLandingTitle = styled.div`
  .landing-title {
    height: 800px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.darkGray};

    &_content {
      margin-left: 10em;
      h1 {
        font-size: ${({ theme }) => theme.fontSizes.h1.xxl};
        font-weight: ${({ theme }) => theme.fontWeights[4]};
        line-height: ${({ theme }) => theme.lineHeights.copy};
        margin-bottom: 1em;
      }

      button {
        font-size: ${({ theme }) => theme.fontSizes.l};
        color: ${({ theme }) => theme.colors.white};
        border-radius: 3px;
        padding: .8em 1.5em;
        background-color: ${({ theme }) => theme.colors.tycoonMain};
      }
    }
  }
`;