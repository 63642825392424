import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Card from '../../common/components/Card';
import BackgroundContainer from '../BackgroundContainer';
import { StyledLandingKeyFeatures } from './ladningKeyFeatures.styled';

const LandingKeyFeatures = () => {
  const { cardOne, circle } = useStaticQuery(
    graphql`
      query {
        cardOne: file(relativePath: {eq: "bullet-one.svg"}) {
          extension
          publicURL
        }
        circle: file(relativePath: {eq: "circle.png"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  return (
    <StyledLandingKeyFeatures>
      <BackgroundContainer
        className="landing_key-features"
        fluid={circle.childImageSharp.fluid}
        backgroundPosition="top 10% right 10%"
      >
        <h1>Discover the key features</h1>
        <div className="landing_key-features_cards">
          <Card title="Profit Share" icon={cardOne.publicURL} />
          <Card title="Leaderboards" icon={cardOne.publicURL} />
          <Card title="Simple and Intuitive" icon={cardOne.publicURL} />
          <Card title="Confidentiality" icon={cardOne.publicURL} />
          <Card title="Tycoon's Token" icon={cardOne.publicURL} />
          <Card title="Copy Trading" icon={cardOne.publicURL} />
          <Card title="Following Traders" icon={cardOne.publicURL} />
          <Card title="Copy Traders" icon={cardOne.publicURL} />
        </div>
      </BackgroundContainer>
    </StyledLandingKeyFeatures>
  );
};

export default LandingKeyFeatures;
