import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Bullet from '../../common/components/Bullet';
import { StyledLandingAchievements } from './landingAchievements.styled';

const LandingAchievements = () => {
  const { bulletOne, bulletTwo, bulletThree, bulletFour } = useStaticQuery(
    graphql`
      query {
        bulletOne: file(relativePath: {eq: "bullet-one.svg"}) {
          extension
          publicURL
        }
        bulletTwo: file(relativePath: {eq: "bullet-two.svg"}) {
          extension
          publicURL
        }
        bulletThree: file(relativePath: {eq: "bullet-three.svg"}) {
          extension
          publicURL
        }
        bulletFour: file(relativePath: {eq: "bullet-four.svg"}) {
          extension
          publicURL
        }
      }
    `
  );

  return (
    <StyledLandingAchievements>
      <div className="landing_achievements_title">
        <h1>Our 18 years of<br /> achievements</h1>
        <span>With our super powers we have reached this</span>
      </div>
      <div className="landing_achievements_bullets">
        <Bullet
          title="10,000+"
          text="Downloads per day"
          icon={bulletOne.publicURL}
        />
        <Bullet
          title="2 Million"
          text="Users"
          icon={bulletTwo.publicURL}
        />
        <Bullet
          title="500+"
          text="Clients"
          icon={bulletThree.publicURL}
        />
        <Bullet
          title="140"
          text="Countries"
          icon={bulletFour.publicURL}
        />
      </div>
    </StyledLandingAchievements>
  );
};

export default LandingAchievements;
