import styled from 'styled-components';

export const StyledLandingBenefits = styled.div`
padding: 20px 0;

.landing-benefits_partners {
    box-sizing: border-box;
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 100px;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin-bottom: 60px;
  }

  .landing-benefits_list {
    width: 85%;
    margin: 0 auto;

    h1 {
      font-size: ${({ theme }) => theme.fontSizes.h1.xl};
      font-weight: ${({ theme }) => theme.fontWeights[4]};
      margin-bottom: 60px;
    }


    &_bullets {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 100px;

      &_item {
        flex-basis: 50%;
        margin-bottom: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        .bullet_icon {
          padding: 10px;
          background-color: ${({ theme }) => theme.colors.lightGray};
          img {
            width: 15px;
          }
        } 
      }
    }
  }
`;