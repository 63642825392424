import styled from 'styled-components';

export const StyledLandingKeyFeatures = styled.div`
  .landing_key-features {
    width: 85%;
    margin: 100px auto;

    h1 {
      font-size: ${({ theme }) => theme.fontSizes.h1.xl};
      font-weight: ${({ theme }) => theme.fontWeights[4]};
      margin-bottom: 1em;
    }

    &_cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      & > * {
      flex-basis: 20%;
      margin-bottom: 20px;
      }
    }
  }
`;