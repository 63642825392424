import React from 'react';

import { StyledLandingFeedbacks } from './landingFeedbacks.styled';

const LandingFeedbacks = () => {
  return (
    <StyledLandingFeedbacks>
      <div className="landing_feedbacks_description">
        <h1></h1>
        <p></p>
      </div>
      <div className="landing_feedbacks_carousel-container"></div>
    </StyledLandingFeedbacks>
  );
};

export default LandingFeedbacks;
