import styled from 'styled-components';

export const StyledLandingHeadline = styled.div`
  width: 85%;
  margin: 100px auto;
  height: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .landing_headline_image-container {
    flex-basis: 40%;
    height: 100%;

    &_image {
      height: 50%;
    }
  }

  .landing_headline_content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: 50%;
    flex-basis: 40%;

    h1 {
      font-size: ${({ theme }) => theme.fontSizes.h1.xl};
      font-weight: ${({ theme }) => theme.fontWeights[4]};
    }

    button {
      color: ${({ theme }) => theme.colors.white};
      font-size: 1em;
      border-radius: 3px;
      padding: .5em 1em;
      background-color: ${({ theme }) => theme.colors.tycoonMain};
    }
  }
`;