import styled from 'styled-components';

export const StyledBullet = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;

  .bullet_icon {
    border-radius: 50%;
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      display: inline-block;
    }
  }

  .bullet_content {
    h3 {
      font-size: ${({ theme }) => theme.fontSizes.h3.s};
      font-weight: ${({ theme }) => theme.fontWeights[4]};
      margin-bottom: .5em;
    }
  }
`;
