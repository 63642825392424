import React from 'react';
import PropTypes from 'prop-types';

import { StyledBullet } from './bullet.styled';

const Bullet = ({ title, text, icon, className }) => {
  return (
    <StyledBullet className={className}>
      <div className="bullet_icon">
        <img src={icon} alt={title} />
      </div>
      <div className="bullet_content">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </StyledBullet>
  );
};

Bullet.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string
};

Bullet.defaultProps = {
  title: 'Title',
  text: 'Text'
};

export default Bullet;
