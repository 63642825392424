import React from 'react';

import LandingTitle from '../LandingTitle';
import LandingBenefits from '../LandingBenefits';
import LandingAchievements from '../LandingAchievements';
import LandingHeadline from '../LandingHeadline';
import LandingKeyFeatures from '../LandingKeyFeatures';
import LandingManageWithMobile from '../LandingManageWithMobile';
import LandingFeedbacks from '../LandingFeedbacks';
import { StyledLanding } from './landing.styled';

const Landing = () => {
  return (
    <StyledLanding>
      <LandingTitle />
      <LandingBenefits />
      <LandingFeedbacks />
      <LandingAchievements />
      <LandingHeadline />
      <LandingKeyFeatures />
      <LandingManageWithMobile />
    </StyledLanding>
  );
};

export default Landing;