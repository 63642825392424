import styled from 'styled-components';

export const StyledCard = styled.div`
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 10px 0px rgba(34, 60, 80, 0.2);
  border-radius: 10px;

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.h3.s};
    font-weight: ${({ theme }) => theme.fontWeights[4]};
    margin-bottom: 1em;
  }

  img {
    display: inline-block;
    width: 2em;
  }
`;