import styled from 'styled-components';

export const StyledLandingAchievements = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  & > * {
    flex-basis: 50%;
  }

  .landing_achievements_title {
    h1 {
      font-size: ${({ theme }) => theme.fontSizes.h1.xl};
      font-weight: ${({ theme }) => theme.fontWeights[4]};
      margin-bottom: .3em;
    }

    span {
      color: ${({ theme }) => theme.colors.gray};
    }
  }
  .landing_achievements_bullets {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex-basis: 50%;
      margin-bottom: 20px;
    }
  }
`;
