import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import LinkComponent from '../../common/components/LinkComponent';
import BackgroundContainer from '../BackgroundContainer';
import { StyledLandingManageWithMobile } from './landingManageWithMobile.styled';

const LandingManageWithMobile = () => {
  const { phoneMockupThree, phoneMockupTwo } = useStaticQuery(
    graphql`
      query {
        phoneMockupThree: file(relativePath: {eq: "phone-mockup-three.png"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        phoneMockupTwo: file(relativePath: {eq: "phone-mockup-two.png"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <StyledLandingManageWithMobile>
      <BackgroundContainer
        className="landing_manage-with-mobile"
        fluid={[
          phoneMockupTwo.childImageSharp.fluid,
          phoneMockupThree.childImageSharp.fluid
        ]}
        backgroundPosition="top left 60%, bottom right 20%"
      >
        <div className="landing_manage-with-mobile_content">
          <h1>Manage your projects from your mobile</h1>
          <p>Download the app to manage your projects, keep track of the progress and complete tasks without procastinating. Stay on track and complete on time!</p>
          <div className="landing_manage-with-mobile_content_links">
            <div>Get the App</div>
            <LinkComponent to="https://play.google.com/store" title="Google Play" target="_blank" type="external">
              <StaticImage src="../../common/assets/image/googlePlayBadge.png" alt="Google Play" />
            </LinkComponent>
            <LinkComponent to="https://www.apple.com/ru/app-store/" title="App Store" target="_blank" type="external">
              <StaticImage src="../../common/assets/image/appStoreBadge.png" alt="App Store" />
            </LinkComponent>
          </div>
        </div>
      </BackgroundContainer>
    </StyledLandingManageWithMobile>
  );
};

export default LandingManageWithMobile;
